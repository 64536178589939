import React from 'react';
import { Create, Datagrid, Edit, List, SimpleForm, TextField, TextInput, SelectInput, Filter, EditButton } from 'react-admin';


const CompanyFilter = (props: any) => (
    <Filter {...props}>
        <TextInput source="name" alwaysOn placeholder="Name" />
    </Filter>
);


export const CompanyList = (props: any) => (
    <List {...props} filters={<CompanyFilter></CompanyFilter>}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="smart-phone" label="Smart phone" />
            <TextField source="terminal" />
            <TextField source="watch" />
            <TextField source="registration" />
            <TextField source="billing" />
            <TextField source="advertising" />
            <EditButton />
        </Datagrid>
    </List>
);

export const CompanyEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>

            <TextInput source="name" />
            <SelectInput source="smart-phone" choices={[
                { id: 'Yes', name: 'Yes' },
                { id: 'No', name: 'No' },
            ]} />
            <SelectInput source="terminal" choices={[
                { id: 'App', name: 'App' },
                { id: 'Pub', name: 'Pub' },
            ]} />
            <SelectInput source="watch" choices={[
                { id: 'Yes', name: 'Yes' },
                { id: 'No', name: 'No' },
            ]} />
            <SelectInput source="registration" choices={[
                { id: 'Yes', name: 'Yes' },
                { id: 'No', name: 'No' },
            ]} />
            <SelectInput source="billing" choices={[
                { id: 'Yes', name: 'Yes' },
                { id: 'No', name: 'No' },
            ]} />
            <SelectInput source="advertising" choices={[
                { id: 'Standard', name: 'Standard' },
                { id: 'Customised', name: 'Customised' },
            ]} />
        </SimpleForm>
    </Edit>
);

export const CompanyCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <SelectInput source="smart-phone" choices={[
                { id: 'Yes', name: 'Yes' },
                { id: 'No', name: 'No' },
            ]} />
            <SelectInput source="terminal" choices={[
                { id: 'App', name: 'App' },
                { id: 'Pub', name: 'Pub' },
            ]} />
            <SelectInput source="watch" choices={[
                { id: 'Yes', name: 'Yes' },
                { id: 'No', name: 'No' },
            ]} />
            <SelectInput source="registration" choices={[
                { id: 'Yes', name: 'Yes' },
                { id: 'No', name: 'No' },
            ]} />
            <SelectInput source="billing" choices={[
                { id: 'Yes', name: 'Yes' },
                { id: 'No', name: 'No' },
            ]} />
            <SelectInput source="advertising" choices={[
                { id: 'Standard', name: 'Standard' },
                { id: 'Customised', name: 'Customised' },
            ]} />
            <SelectInput source="payment" choices={[
                { id: 'None ', name: 'None' },
                { id: 'CB', name: 'CB' },
                { id: 'Specific cards', name: 'Specific cards' },
            ]} />
        </SimpleForm>
    </Create>
);
