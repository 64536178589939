import React from 'react';
import {
    BooleanInput,
    Button,
    ChipField,
    Create,
    Datagrid,
    DeleteButton,
    Edit,
    EmailField,
    Filter,
    FormTab,
    ImageField,
    ImageInput,
    List,
    PasswordInput,
    RadioButtonGroupInput,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    SelectInput,
    SimpleForm,
    SingleFieldList,
    TabbedForm,
    TextField,
    TextInput,
    EditButton
} from 'react-admin';
import Avatar from '@material-ui/core/Avatar';
import { API_URL } from '../constant';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import { Link } from 'react-router-dom';
import { DateInput } from '../react-admin-date-inputs';

const AvatarShow = ({ record = {}, size }: any) => {
    size = size || 32;
    const extra = { label: 'avatar' };
    return (
        record.avatar ? <Avatar {...extra}
            src={`${API_URL}/Containers/photos/download/${record.avatar}`}
            style={{
                width: size,
                height: size
            }}
        /> : null
    );
};


const AddRoleButton = ({ classes, record }: any) => (
    <Button
        component={Link}
        to={`/RoleMappings/create?principalId=${record.id}`}
        label="Add"
        title="Add"
    >
        <VpnKeyIcon />
    </Button>
);


const EditRoleButton = ({ classes, record }: any) => (
    <Button
        component={Link}
        to={`/RoleMappings/edit?principalId=${record.id}`}
        label="Edit"
        title="Edit"
    >
        <VpnKeyIcon />
    </Button>
);

const DeleteRoleButton = (props: any) => (
    <DeleteButton {...props} redirect={`/Accounts/${props.record.id}/roles`} />
);

const AccountFilter = (props: any) => (
    <Filter {...props}>
        <TextInput source="username" alwaysOn />
    </Filter>
);


export const AccountList = (props: any) => (
    <List {...props} filters={<AccountFilter />}>

        <Datagrid >
            <AvatarShow />
            <TextField source="id" />
            <TextField source="nom" />
            <TextField source="prenom" />
            <EmailField source="email" />
            <TextField source="username" />
            <ReferenceManyField target="principalId" reference="RoleMappings">
                <SingleFieldList>
                    <ReferenceField source="roleId" reference="Roles" link={false}>
                        <ChipField source="name" />
                    </ReferenceField>
                </SingleFieldList>
            </ReferenceManyField>
            <ReferenceField source="companyId" reference="Companies">
                <TextField source="name" />
            </ReferenceField>
            <EditButton/>
            {/* <TextField source="sex"/> */}
            {/* <DateField source="birthday"/>
            <TextField source="addresse"/>
            <TextField source="stripeCustomerId"/> */}
            {/* <BooleanField source="alreadyLogin"/>
            <AvatarShow/>
            <TextField source="username"/>
           
            <BooleanField source="emailVerified"/> */}


        </Datagrid>
    </List>
);

export const AccountEdit = (props: any) => (
    <Edit {...props}>
        <TabbedForm>

            <FormTab label="summary">
                <AvatarShow label="Smart phone" />
                <ImageInput source="pictures" label="Upload new avatar" accept="image/*" multiple={false}>
                    <ImageField source="src" title="title" />
                </ImageInput>
                <TextInput disabled source="id" />
                <TextInput source="nom" />
                <TextInput source="prenom" />
                <RadioButtonGroupInput source="sex" choices={[
                    { id: 'female', name: 'Female' },
                    { id: 'male', name: 'Male' }
                ]} />
                <DateInput source="birthday" />
                <TextInput source="addresse" />
                <TextInput source="stripeCustomerId" />
                <BooleanInput source="alreadyLogin" />

                <TextInput source="username" />
                <TextInput source="email" />
                <BooleanInput source="emailVerified" />
                <ReferenceInput source="companyId" reference="Companies">
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </FormTab>
            <FormTab label="roles" path="roles">
                <ReferenceManyField addLabel={false} target="principalId" reference="RoleMappings">
                    <Datagrid>
                        <ReferenceField source="roleId" reference="Roles">
                            <TextField source="name" />
                        </ReferenceField>
                        <EditRoleButton />
                        <DeleteRoleButton />
                    </Datagrid>
                </ReferenceManyField>
                <AddRoleButton />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const AccountCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="nom" />
            <TextInput source="prenom" />
            <RadioButtonGroupInput source="sex" choices={[
                { id: 'female', name: 'Female' },
                { id: 'male', name: 'Male' }
            ]} />
            <DateInput source="birthday" />
            <TextInput source="addresse" />
            <TextInput source="stripeCustomerId" />
            <ImageInput source="avatar" accept="image/*" multiple={false}>
                <ImageField source="src" title="title" />
            </ImageInput>
            <TextInput source="username" />
            <TextInput source="email" />
            <PasswordInput source="password" />
            <ReferenceInput source="companyId" reference="Companies">
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
