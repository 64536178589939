import {
    Create,
    Datagrid,
    Edit,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput
} from "react-admin";
import React from 'react';
import { parse } from "query-string";

export const RolemappingList = (props: any) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="principalType"/>
            <ReferenceField source="principalId" reference="Accounts">
                <TextField source="username"/>
            </ReferenceField>
            <ReferenceField source="roleId" reference="Roles">
                <TextField source="name"/>
            </ReferenceField>
        </Datagrid>
    </List>
);

export const RolemappingEdit = (props: any) => {
    const { principalId: post_id_string } = parse(props.location.search);
    const principalId = post_id_string ? parseInt(post_id_string as string, 10) : '';
    const redirect = principalId ? `/Accounts/${principalId}/edit/roles` : false;

    return (
        <Edit {...props}>
            <SimpleForm initialValues={{ principalId }}
                        redirect={redirect}>
                <TextInput disabled source="principalType"/>
                <ReferenceInput source="principalId" reference="Accounts">
                    <SelectInput disabled={principalId ? true : null} optionText="username"/>
                </ReferenceInput>
                <ReferenceInput source="roleId" reference="Roles">
                    <SelectInput optionText="name"/>
                </ReferenceInput>

            </SimpleForm>
        </Edit>
    );
};

export const RolemappingCreate = (props: any) => {
    const { principalId: post_id_string } = parse(props.location.search);
    const principalId = post_id_string ? parseInt(post_id_string as string, 10) : '';
    const redirect = principalId ? `/Accounts/${principalId}/edit/roles` : false;

    return (
        <Create {...props}>
            <SimpleForm initialValues={{ principalId }}
                        redirect={redirect}>
                <TextInput source="principalType" initialValue="USER"/>
                <ReferenceInput source="principalId" reference="Accounts">
                    <SelectInput disabled={principalId ? true : null} optionText="username"/>
                </ReferenceInput>
                <ReferenceInput source="roleId" reference="Roles">
                    <SelectInput optionText="name"/>
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};
