import React, { Component } from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import FolderIcon from '@material-ui/icons/Folder';
import EuroIcon from '@material-ui/icons/Euro';
import Battery90Icon from '@material-ui/icons/Battery90';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import MoneyRoundedIcon from '@material-ui/icons/MoneyRounded';
import './statistics.scss'
import HourglassFullRoundedIcon from '@material-ui/icons/HourglassFullRounded';
import QueryBuilderRoundedIcon from '@material-ui/icons/QueryBuilderRounded';
import axios from 'axios'
import { API_URL } from '../../../constant';
export class Statistics extends Component {
    render() {
        return (
            <div>
                <Company />
                <DataStatistics />
                <TableTopStations />
            </div>
        );
    }
}


class Company extends Component {
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-3">
                        <h5>Company</h5>
                    </div>

                    <div className="col-md-9">
                        <h5>Standard</h5>
                    </div>
                </div>
            </div>
        )
    }
}
class TableTopStations extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            dataStations: []
        }
        const filter = { "where": {}, "order": ["id DESC"], "limit": 3, "skip": 0 }
        axios.get(`${API_URL}/Stations`, { params: { filter } }).then(response => {
            this.setState({ dataStations: response.data })
        }).catch(error => {
            console.log(error);

        })
    }

    render() {
        return (
            <div>
                <h5>Top station</h5>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Address</th>
                            <th scope="col">Status</th>
                            <th scope="col">Installation</th>
                            <th scope="col">Type</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.dataStations.map((station: any) =>
                                (
                                    <tr>
                                        <th scope="row">{station.id}</th>
                                        <td>{station.name}</td>
                                        <td>{station.address}</td>
                                        <td>{station.status}</td>
                                        <td>{station.installationDate}</td>
                                        <td>{station.type}</td>
                                        <td><a className="btn btn-primary btn-sm" href={`#/Stations/` + station.id}>View</a></td>
                                    </tr>
                                )
                            )
                        }
                    </tbody>
                </table>
            </div>
        );
    }

}
const DataStatistics = () => {
    return (
        <div className="data-statistics">
            <hr />
            <h5>Data</h5>
            <hr />
            <div className="row">
                <div className="col-4">
                    <div className="card-data total-battery-usage">
                        <div className="row card-top">
                            <div className="col-3"> <SettingsIcon /> </div>
                            <div className="col-9 card-top-data">
                                <h3>200.000</h3>
                                <span>Utilisation de batteries</span>
                            </div>
                        </div>
                        <div className="card-bottom">
                            <div className="row">
                                <div className="col-4">
                                    <span>Mini</span><br />
                                    <span>20.000</span>
                                </div>
                                <div className="col-4">
                                    <span>Kiosk</span><br />
                                    <span>20.000</span>
                                </div>
                                <div className="col-4">
                                    <span>Totem</span><br />
                                    <span>20.000</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card-data selections">
                        <div className="row card-top">
                            <div className="col-3">  <FolderIcon /> </div>
                            <div className="col-9 card-top-data">
                                <h4>10.000</h4>
                                <span>Meilleure catégorie (nom)</span>
                            </div>
                        </div>
                        <div className="card-bottom">
                            <h4>1.000</h4>
                            <span>Moins bonne catégorie (nom)</span>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card-data fund">
                        <div className="row">
                            <div className="col-md-3"><EuroIcon /></div>
                            <div className="col-md-9 fund-data">
                                <div>
                                    <h4>20.000 (TTC)</h4>
                                    <span>Fonds reversés</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <div className="card-data returns">
                        <div className="row card-top">
                            <div className="col-3">  <KeyboardReturnIcon /> </div>
                            <div className="col-9 card-top-data">
                                <h3>150.000</h3>
                                <span>Retours</span>
                            </div>
                        </div>
                        <div className="card-bottom">
                            <div className="row">
                                <div className="col-4">
                                    <span>Mini</span><br />
                                    <span>20.000</span>
                                </div>
                                <div className="col-4">
                                    <span>Kiosk</span><br />
                                    <span>20.000</span>
                                </div>
                                <div className="col-4">
                                    <span>Totem</span><br />
                                    <span>20.000</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card-data batteries">
                        <div className="row card-top">
                            <div className="col-3">  <Battery90Icon /> </div>
                            <div className="col-9 card-top-data">
                                <h4>1.000</h4>
                                <span>Batteries manquanters</span>
                            </div>
                        </div>
                        <div className="card-bottom">
                            <h4>25</h4>
                            <span>Id de la station</span>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card-data money">
                        <div className="row card-top">
                            <div className="col-3">  <MoneyRoundedIcon /> </div>
                            <div className="col-9 card-top-data">
                                <h4>10.000</h4>
                                <span>Meilleure catégorie (nom)</span>
                            </div>
                        </div>
                        <div className="card-bottom">
                            <h4>1.000</h4>
                            <span>Moins bonne catégorie (nom)</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <div className="card-data delays">
                        <div className="row card-top">
                            <div className="col-3">  <HourglassFullRoundedIcon /> </div>
                            <div className="col-9 card-top-data">
                                <h4>50.000</h4>
                                <span>Délais</span>
                            </div>
                        </div>
                        <div className="card-bottom">
                            <h5>81.056 (TTC)</h5>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card-data hours">
                        <div className="row card-top">
                            <div className="col-3">  <QueryBuilderRoundedIcon /> </div>
                            <div className="col-9 card-top-data">
                                <h4>16h30</h4>
                                <span>Heure pleine</span>
                            </div>
                        </div>
                        <div className="card-bottom">
                            <h4>8h30</h4>
                            <span>Heure creuse</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}