import React from 'react';
import {
    Create,
    Datagrid,
    DateField,
    Edit,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    EditButton
} from 'react-admin';


export const CategoryList = (props: any) => (
    <List {...props}>
        <Datagrid >
            <TextField source="id" />
            <TextField source="name" />
            <ReferenceField source="companyId" reference="Companies">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="level" />
            <TextField source="parent" />
            <DateField source="createdAt" />
            <EditButton/>
        </Datagrid>
    </List>
);

export const CategoryEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="name" />
            <ReferenceInput source="companyId" reference="Companies">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <SelectInput source="level" choices={[
                { id: '1', name: '1' },
                { id: '2', name: '2' },
                { id: '3', name: '3' },
                { id: '4', name: '4' },
            ]} />
            <SelectInput source="parent" choices={[
                { id: 'Root', name: 'Root' },
                { id: 'Created categories', name: 'Created categories' },
            ]} />

        </SimpleForm>
    </Edit>
);

export const CategoryCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceInput source="companyId" reference="Companies">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <SelectInput source="level" choices={[
                { id: '1', name: '1' },
                { id: '2', name: '2' },
                { id: '3', name: '3' },
                { id: '4', name: '4' },
            ]} />
            <SelectInput source="parent" choices={[
                { id: 'Root', name: 'Root' },
                { id: 'Created categories', name: 'Created categories' },
            ]} />
        </SimpleForm>

    </Create>
);
