import React, { Component } from 'react';
import { Create, Datagrid, DateField, Edit, List, SimpleForm, TextField, TextInput, SelectInput, ImageInput, ImageField, FileInput, EditButton, FileField, Filter } from 'react-admin';
import { API_URL } from '../constant';
import { PaperProps, Paper, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import Draggable from 'react-draggable';
import ClearIcon from '@material-ui/icons/Clear';
function PaperComponent(props: PaperProps) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const OpenMedias = (props: any) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    let showMedia;
    const typeMedia = props.record.type;
    const style = {
        img_size: {
            width: '100%'
        },
        video_size:{
            width: '100%'
        }
    }
    if (typeMedia === 'image') {
        showMedia =
            <div>
                <img
                    style={style.img_size}
                    src={`${API_URL}/Containers/Advertisements/download/${props.record.Asset}`}
                />
            </div>
    }
    else {
        showMedia =
            <div>
                <video style={style.video_size} controls> <source
                    src={`${API_URL}/Containers/Advertisements/download/${props.record.Asset}`}
                /></video>
            </div>
    }
    return (
        <div>
            <div>
                <Button  color="primary" onClick={handleClickOpen}> Open </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        <div className="row">
                            <div className="col-10">
                                Preview media advertisement
                            </div>
                            <div className="col-1">
                                <Button onClick={handleClose} color="primary" title="Close">
                                    <ClearIcon />
                                </Button>
                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        {showMedia}
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    );

}
const ShowMedia = ({ record = {} }: any) => {
    const style = {
        img_size: {
            width: '10rem'
        }
    }
    if (record.type === 'image') {
        return (
            record.Asset ? <img style={style.img_size}
                src={`${API_URL}/Containers/Advertisements/download/${record.Asset}`}
            /> : null
        );
    }
    else {
        return (
            record.Asset ? <video controls> <source
                src={`${API_URL}/Containers/Advertisements/download/${record.Asset}`}
            /></video> : null
        );
    }
};
const AdvertisementFilter = (props: any) => (
    <Filter {...props}>
        <TextInput source="name" alwaysOn />
    </Filter>
);

export const AdvertisementList = (props: any) => (
    <List {...props} filters={<AdvertisementFilter />}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="type" />
            <DateField source="createdAt" showTime />
            <OpenMedias label="Media" />
            <EditButton />
        </Datagrid>
    </List>
);

export const AdvertisementEdits = (props: any) => (
    <Edit {...props}>
        <SimpleForm>

            <TextInput disabled source="id" />
            <TextInput source="name" />
            <TextField source="type" />
            <ShowMedia></ShowMedia>
            <ImageInput source="pictures" label="Upload new avatar" accept="image/*" multiple={false}>
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Edit>
);

export class AdvertisementEdit extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.OnChangeTypeupload = this.OnChangeTypeupload.bind(this);
        this.state = {
            typeUpload: '',
            previewImage: ''
        }
    }


    render() {
        return (
            <Edit {...this.props}>
                <SimpleForm>
                    <TextInput source="name" />
                    <b>Preview media advertisement</b>
                    <ShowMedia></ShowMedia>
                    <br />
                    <b>Upload new media</b>
                    <SelectInput label="Type media" onChange={this.OnChangeTypeupload} source="type" choices={[
                        { id: 'image', name: 'image', },
                        { id: 'video', name: 'video' },
                    ]} />
                    <UploadMedia typeUpload={this.state.typeUpload}></UploadMedia>

                </SimpleForm>
            </Edit>
        );
    }
    OnChangeTypeupload = (event: any) => {
        this.setState({ 'typeUpload': event.target.value })

    }
}

export class AdvertisementCreate extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.OnChangeTypeupload = this.OnChangeTypeupload.bind(this);
        this.state = {
            typeUpload: '',
            previewImage: ''
        }
    }


    render() {
        return (
            <Create {...this.props}>
                <SimpleForm>
                    <TextInput source="name" />
                    <SelectInput label="Type media" onChange={this.OnChangeTypeupload} source="type" choices={[
                        { id: 'image', name: 'image', },
                        { id: 'video', name: 'video' },
                    ]} />
                    <UploadMedia typeUpload={this.state.typeUpload}></UploadMedia>
                </SimpleForm>
            </Create>
        );
    }
    OnChangeTypeupload = (event: any) => {
        this.setState({ 'typeUpload': event.target.value })

    }
}

class UploadMedia extends Component<any, any>{
    constructor(props: any) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            file: null
        }
    }
    handleChange(event: any) {
        this.setState({
            file: URL.createObjectURL(event.target.files[0])
        })
    }
    render() {
        const typeUpload = this.props.typeUpload;
        console.log(typeUpload);
        if (typeUpload === 'image') {
            return (
                <div>
                    <b>Upload image advertiseent</b>
                    <ImageInput source="pictures" accept="image/*" multiple={false}
                        placeholder="Drop a image to upload, or click to select it.">
                        <ImageField source="src" title="title" />
                    </ImageInput>
                </div>
            );
        }
        else if (typeUpload === 'video') {
            return (
                <div>
                    <b>Upload video advertiseent</b>
                    <FileInput label="Video" source="pictures" accept="video/*" multiple={false}
                        placeholder="Drop a video to upload, or click to select it.">
                        <FileField source="src" title="title" />
                    </FileInput>
                </div>
            );
        }
        else if (typeUpload === '') {
            return (
                <div>
                    <span>No media type selected</span>
                </div>
            );
        }
    }
}