import React from 'react';
import {
    Create,
    Datagrid,
    Edit,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    DateTimeInput,
    DateField,
    EditButton
} from 'react-admin';

export const AdvertisementplanList = (props: any) => (
    <List {...props}>
        <Datagrid >
            <TextField source="id"/>
            <DateField source="dateAdvertisement" showTime />
            <DateField source="dateEndAdvertisement" showTime />
            
            <ReferenceField source="advertisementId" reference="Advertisements">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="companyId" reference="Companies">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="categoryId" reference="Categories">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="stationId" reference="Stations">
                <TextField source="name"/>
            </ReferenceField>
            <EditButton label="Edit" />
        </Datagrid>
    </List>
);

export const AdvertisementplanEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id"/>
            <ReferenceInput source="advertisementId" reference="Advertisements">
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <ReferenceInput source="companyId" reference="Companies">
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <ReferenceInput source="categoryId" reference="Categories">
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <ReferenceInput source="stationId" reference="Stations">
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <DateTimeInput source="dateAdvertisement" />
            <DateTimeInput source="dateEndAdvertisement" />
            <SelectInput source="location" choices={[
                { id: 'advertising', name: 'ADVERTISING' },
                { id: 'chrono', name: 'CHRONO' },
            ]} />
        </SimpleForm>
    </Edit>
);

export const AdvertisementplanCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput source="advertisementId" reference="Advertisements">
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <ReferenceInput source="companyId" reference="Companies">
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <ReferenceInput source="categoryId" reference="Categories">
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <ReferenceInput source="stationId" reference="Stations">
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <DateTimeInput source="dateAdvertisement" />
            <DateTimeInput source="dateEndAdvertisement" />
            <SelectInput source="location" choices={[
                { id: 'advertising', name: 'ADVERTISING' },
                { id: 'chrono', name: 'CHRONO' },
            ]} />
        </SimpleForm>
    </Create>
);
