import React, { Component } from 'react';
import {
    Create,
    Datagrid,
    DateField,
    DateInput,
    Edit,
    EditButton,
    List,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    BooleanField
} from 'react-admin';
import { LocationField } from '../components/location-field';
import { Button, PaperProps, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, InputLabel, Select, MenuItem, makeStyles, Theme, createStyles, TableRow, TableCell, Checkbox } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Draggable from 'react-draggable';
import { render } from '@testing-library/react';
import { API_URL } from '../constant';
import axios from 'axios'
import fetchJson from '../react-admin-loopback/fetch'
function PaperComponent(props: PaperProps) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 420,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

const ButtonCmd = (props: any) => {
    const classes = useStyles();
    const _maxNumBattery = props.record.maxNumBattery;
    let maxNumBattery = [];
    for(let i=1;i<=_maxNumBattery;i++){
        maxNumBattery.push(i);
    }
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = (event: any) => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setCmd('');
    };


    const styleInput = {
        input: {
            marginTop: '1rem'
        }
    }
    const [productDetail, setProductDetail] = React.useState();

    const [productID, setProductID] = React.useState()

    const handleSelectProductID = (event: any) => {
        setProductID(event.target.value);
        setProductDetail(listProducts[listProducts.findIndex((i: any) => i.id === event.target.value)]);
    };

    const handleSetProductDetail = (value: any) => {
        // setProductDetail(1);
    }

    const [productSerial, setProductSerial] = React.useState();

    const handleSelectProductSerial = (event: any) => {
        setProductSerial(event.target.value)
    };
    // Handle Command Input
    let [cmd, setCmd] = React.useState('');
    const showSelectProduct = cmd === "ADMIN_RELEASE_AT_INDEX";
    const [listProducts, setListProduct] = React.useState([]);
    React.useEffect(() => {
        if (showSelectProduct) {
            const params = new URLSearchParams();
            params.append('filter', JSON.stringify({ where: { stationId: props.record.id } }));
            fetchJson(`${API_URL}/Products?${params.toString()}`,
                { method: 'GET' })
                .then((response: any) => {
                    setListProduct(response.json)
                }).catch(err => {
                    console.log(err);
                });
        }
    }, [props.record.id, showSelectProduct])

    const showInputSerial = cmd === "ADMIN_RELEASE_WITH_SERIAL";
    React.useEffect(() => {
        if (showInputSerial) {
            const params = new URLSearchParams();
            params.append('filter', JSON.stringify({ where: { stationId: props.record.id, status: 'AVAILABLE'  } }));
            fetchJson(`${API_URL}/Products?${params.toString()}`,
                { method: 'GET' })
                .then((response: any) => {
                    setListProduct(response.json)
                }).catch(err => {
                    console.log(err);
                });
        }
    }, [props.record.id, showInputSerial])

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCmd(event.target.value as string);
        setProductID(undefined);
        setProductDetail(undefined);
        setProductSerial(undefined);
    };

    const createStationCommand = async () => {
        const _stationId = props.record.id;
        let args: any;
        if (cmd === 'ADMIN_RELEASE_ALL') {
            cmd = '';
        }
        else if (cmd === "ADMIN_RELEASE_AT_INDEX") {
            args = { "index": productID }
        }
        else if (cmd === "ADMIN_RELEASE_WITH_SERIAL") args = { "serial": productSerial }
        let dataRequest = {
            "command": cmd,
            "args": args,
            "stationId": _stationId
        }
        const formData = new FormData();
        formData.append('command', cmd);
        formData.append('args', args);
        formData.append('stationId', props.record.id);
        // const response = await fetchJson(`${API_URL}/StationCommands`, { method: 'POST', body: formData });
        axios.post(`${API_URL}/StationCommands`, dataRequest)
            .then(response => {
                setCmd('');
                setOpen(false);
            }).catch(err => {
                console.log(err);
            })
    };
    return (
        <div>
            <Button color="primary" onClick={handleClickOpen} ><ArrowForwardIosIcon />release</Button>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Station Command
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="select-command-label"> Command</InputLabel>
                                <Select
                                    labelId="select-command-label"
                                    id="demo-simple-select"
                                    value={cmd}
                                    onChange={handleChange}
                                >
                                    {_maxNumBattery ?( <MenuItem value={'ADMIN_RELEASE_AT_INDEX'}>ADMIN_RELEASE_AT_INDEX</MenuItem>):null}
                                    <MenuItem value={'ADMIN_RELEASE_WITH_SERIAL'}>ADMIN_RELEASE_WITH_SERIAL</MenuItem>
                                    <MenuItem value={'ADMIN_RELEASE_ALL'}>ADMIN_RELEASE_ALL</MenuItem>
                                </Select>
                            </FormControl>
                            {
                                showSelectProduct ? (
                                    <div>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="select-product-label"> Select Product Index</InputLabel>
                                            <Select
                                                labelId="select-product-label"
                                                onChange={handleSelectProductID}
                                                value={productID}
                                            >
                                                {maxNumBattery.map((indexOfStation: any, index) => (
                                                    <MenuItem value={indexOfStation}>{indexOfStation}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                ) : null
                            }

                            {
                                showInputSerial ? (
                                    <div>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="select-product-label"> Select Product Serial</InputLabel>
                                            <Select
                                                labelId="select-product-label"
                                                onChange={handleSelectProductSerial}
                                                value={productSerial}
                                            >
                                                {listProducts.map((product: any, index) => (
                                                    <MenuItem value={product.serial}>{product.serial}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                ) : null
                            }
                            {/* {
                                productDetail && cmd ? (
                                    <table className="table" style={{ marginTop: '1rem' }}>
                                        <thead>
                                            <tr>
                                                <th scope="col">Id</th>
                                                <th scope="col">Serial</th>
                                                <th scope="col">Percentage</th>
                                                <th scope="col">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">{productDetail.id}</th>
                                                <td>{productDetail.serial}</td>
                                                <td>{productDetail.percentage}</td>
                                                <td>{productDetail.status}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                ) : null
                            } */}

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button disabled={!cmd || (cmd === "ADMIN_RELEASE_AT_INDEX" && !productID) || ((cmd === "ADMIN_RELEASE_WITH_SERIAL" && !productSerial))} variant="contained" onClick={createStationCommand} color="secondary">
                            Send
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div >
    );
}

export const StationList = (props: any) => {
    return (
        <div>
            <List {...props}>
                <Datagrid {...props} >
                    <TextField source="id" />
                    <TextField source="name" />
                    <TextField source="type" />
                    <TextField source="address" />
                    <LocationField source="location" />
                    <DateField source="installationDate" />
                    <TextField source="status" />
                    <BooleanField source="online" />
                    <EditButton />
                    <ButtonCmd name="CMD"></ButtonCmd>
                </Datagrid>
            </List>
        </div>
    );
}
export const StationEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="name" />
            <SelectInput source="online" choices={[
                { id: 'true', name: 'Online' },
                { id: 'false', name: 'Offline' },
            ]} />
            <SelectInput source="type" choices={[
                { id: 'mini', name: 'Mini' },
                { id: 'totem', name: 'Totem' },
                { id: 'kiosk', name: 'Kiosk' },
            ]} />
            <TextInput source="address" />
            <NumberInput source="location.lat" />
            <NumberInput source="location.lng" />
            <DateInput source="installationDate" />
            <SelectInput source="status" choices={[
                { id: 'ACTIVATED', name: 'ACTIVATED' },
                { id: 'DEACTIVATED', name: 'DEACTIVATED' },
            ]} />
            <ReferenceInput source="accountsId" reference="Accounts">
                <SelectInput optionText="username" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export const StationCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <SelectInput source="type" choices={[
                { id: 'mini', name: 'Mini' },
                { id: 'totem', name: 'Totem' },
                { id: 'kiosk', name: 'Kiosk' },
            ]} />
            <TextInput source="address" />
            <NumberInput source="location.lat" />
            <NumberInput source="location.lng" />
            <DateInput source="installationDate" />
            <SelectInput source="status" choices={[
                { id: 'ACTIVATED', name: 'ACTIVATED' },
                { id: 'DEACTIVATED', name: 'DEACTIVATED' },
            ]} />
            <ReferenceInput source="accountsId" reference="Accounts">
                <SelectInput optionText="username" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
