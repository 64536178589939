import React from 'react';
import {
    Create,
    Datagrid,
    DateField,
    Edit,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';

export const ReceiptList = (props: any) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="receipt"/>
            <TextField source="id"/>
            <ReferenceField source="accountId" reference="Accounts">
                <TextField source="username"/>
            </ReferenceField>
            <ReferenceField source="productId" reference="Products">
                <TextField source="name"/>
            </ReferenceField>
            <DateField source="createdAt"/>
            <DateField source="updatedAt"/>
        </Datagrid>
    </List>
);

export const ReceiptEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id"/>
            <TextInput source="receipt"/>
            <ReferenceInput source="accountId" reference="Accounts">
                <SelectInput optionText="username"/>
            </ReferenceInput>
            <ReferenceInput source="productId" reference="Products">
                <SelectInput optionText="serial"/>
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export const ReceiptCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="receipt"/>
            <ReferenceInput source="accountId" reference="Accounts">
                <SelectInput optionText="username"/>
            </ReferenceInput>
            <ReferenceInput source="productId" reference="Products">
                <SelectInput optionText="serial"/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
