import React from 'react';
import { Admin, Resource } from 'react-admin';
import 'bootstrap/dist/css/bootstrap.min.css';
import loopbackClient, { authProvider } from './react-admin-loopback';
import { ProductCreate, ProductEdit, ProductList } from './pages/products';
import { CompanyCreate, CompanyEdit, CompanyList } from './pages/companies';
import { AccountCreate, AccountEdit, AccountList } from './pages/accounts';
import { RoleCreate, RoleEdit, RoleList } from './pages/roles';
import { Statistics } from '../src/pages/my-layout/statistics/statistics';
import { StationCreate, StationEdit, StationList } from './pages/stations';
import { CategoryCreate, CategoryEdit, CategoryList } from './pages/categories';
import { AdvertisementCreate, AdvertisementEdit, AdvertisementList } from './pages/advertisementx';
import { AdvertisementplanCreate, AdvertisementplanEdit, AdvertisementplanList } from './pages/advertisement-plans';
import { ReceiptCreate, ReceiptEdit, ReceiptList } from './pages/receipts';
import { SystemlogEdit, SystemlogList } from './pages/system-logs';
import { RolemappingCreate, RolemappingEdit } from './pages/role-mappings';
import { API_URL } from './constant';
import fetchJson from './react-admin-loopback/fetch';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import BusinessIcon from '@material-ui/icons/Business';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Battery90Icon from '@material-ui/icons/Battery90';
import EvStationIcon from '@material-ui/icons/EvStation';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ClassIcon from '@material-ui/icons/Class';
import MovieIcon from '@material-ui/icons/Movie';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DescriptionIcon from '@material-ui/icons/Description';

const withUpload = (requestHandler: (type: any, resource: any, params: any) => any) =>
    async (type: string, resource: string, params: { data: any, previousData: any }) => {
    if(resource === 'Advertisements' && (type === 'UPDATE' || type === 'CREATE')) {
        const { pictures, ...data } = params.data
        if (!pictures && params.data.type !== params.previousData.type) {
            return Promise.resolve({ data: params.previousData })      
        }
        if (!pictures) {
            return requestHandler(type, resource, params);
        }
        if (!pictures.rawFile.type.startsWith(params.data.type)) {
            throw new Error("The uploaded media does not match the selected media type");
            return Promise.resolve({ data: params.previousData })
        }

        const formData = new FormData();
        formData.append('file', pictures.rawFile);
        const json = await fetchJson(`${API_URL}/Containers/Advertisements/upload`, { method: 'POST', body: formData });
        
        return requestHandler(type, resource, {
            ...params, data: {
                ...data,
                Asset: json.json.result.files.file[0].name
            }
        });
    }

    if ((type === 'UPDATE' || type === 'CREATE') && resource === 'Accounts') {
        const { pictures, ...data } = params.data;
        if (pictures && pictures.rawFile) {
            const formData = new FormData();
            formData.append('file', pictures.rawFile);
            const options = {
                method: 'POST',
                body: formData
            };
            if(resource === 'Accounts')
            {
                const json = await fetchJson(`${API_URL}/Containers/avatar/upload`, options);
                return requestHandler(type, resource, {
                    ...params, data: {
                        ...data,
                        avatar: json.json.result.files.file[0].name
                    }
                });
            }
        }
        return requestHandler(type, resource, { ...params, data: { ...data } });
    }
    return requestHandler(type, resource, params);
};

const App = () => (
    <Admin
        dataProvider={withUpload(loopbackClient(`${API_URL}`))}
        authProvider={authProvider(`${API_URL}/Accounts/login`)}>
        <Resource name="Accounts" icon={SupervisorAccountIcon} list={AccountList} edit={AccountEdit} create={AccountCreate} />
        <Resource name="Roles" icon={VpnKeyIcon} list={RoleList} edit={RoleEdit} create={RoleCreate} />
        <Resource name="RoleMappings" edit={RolemappingEdit} create={RolemappingCreate} />
        <Resource name="Companies" icon={BusinessIcon} list={CompanyList} edit={CompanyEdit} create={CompanyCreate} />
        <Resource name="Products" icon={Battery90Icon} list={ProductList} edit={ProductEdit} create={ProductCreate} />
        <Resource name="Stations" icon={EvStationIcon} list={StationList} edit={StationEdit} create={StationCreate} />
        <Resource name="Categories" icon={ClassIcon} list={CategoryList} edit={CategoryEdit} create={CategoryCreate} />
        <Resource name="Advertisements" icon={MovieIcon} list={AdvertisementList} edit={AdvertisementEdit}
            create={AdvertisementCreate} />
        <Resource name="AdvertisementPlans" icon={CalendarTodayIcon} list={AdvertisementplanList}
            edit={AdvertisementplanEdit}
            create={AdvertisementplanCreate} />
        <Resource name="Receipts" icon={ReceiptIcon} list={ReceiptList} edit={ReceiptEdit} create={ReceiptCreate} />
        <Resource options={{ label: 'Statistics' }} name="Statistics" icon={TrendingUpIcon} list={Statistics}>
            <h1>hello</h1>
        </Resource>
        <Resource  name="SystemLogs" icon={DescriptionIcon} list={SystemlogList} edit={SystemlogEdit} />
    </Admin>
);

export default App;

