import { AUTH_CHECK, AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT } from 'react-admin';
import storage from './storage';
import fetchJson from '../react-admin-loopback/fetch';
import { API_URL } from '../constant'
export const authProvider = (loginApiUrl: string, noAccessPage = '/login') => {
    return (type: string, params: any) => {
        if (type === AUTH_LOGIN) {
            const request = new Request(loginApiUrl, {
                method: 'POST',
                body: JSON.stringify(params),
                headers: new Headers({ 'Content-Type': 'application/json' })
            });
            return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                    return response.json();
                })
                .then(async ({ ttl, ...data }) => {
                    const params = new URLSearchParams();
                    params.append('filter', JSON.stringify({ "where": { "principalId": data.userId} }));
                    const response = await fetchJson(`${API_URL}/RoleMappings/?${params}`, { method: 'GET' })
                    const roles:any[] = response.json;
                    if(roles.findIndex(i=>i.roleId===1 || i.roleId===2)>=0){
                        storage.save('lbtoken', data, ttl);
                    }
                    else{
                        throw new Error("This account does not have permission to access the page");
                    }
                });
        }
        if (type === AUTH_LOGOUT) {
            storage.remove('lbtoken');
            return Promise.resolve();
        }
        if (type === AUTH_ERROR) {
            const { status } = params;
            if (status === 401 || status === 403) {
                storage.remove('lbtoken');
                return Promise.reject();
            }
            return Promise.resolve();
        }
        if (type === AUTH_CHECK) {
            const token = storage.load('lbtoken');
            if (token && token.id) {
                return Promise.resolve();
            } else {
                storage.remove('lbtoken');
                return Promise.reject({ redirectTo: noAccessPage });
            }
        }
        return Promise.reject('Unknown method');
    };
};
