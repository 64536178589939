import React from 'react';
import { Create, Datagrid, DateField, Edit, List, SimpleForm, TextField, TextInput, EditButton } from 'react-admin';

export const RoleList = (props: any) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="description"/>
            <DateField source="created"/>
            <DateField source="modified"/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const RoleEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id"/>
            <TextInput disabled source="name"/>
            <TextInput source="description"/>
        </SimpleForm>
    </Edit>
);

export const RoleCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name"/>
            <TextInput source="description"/>
        </SimpleForm>
    </Create>
);
