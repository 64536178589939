import React from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    Edit,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';

export const SystemlogList = (props: any) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="type"/>
            <TextField source="method"/>
            <TextField source="accessType"/>
            <TextField source="process"/>
            <TextField source="log"/>
            <TextField source="severity"/>
            <ReferenceField source="accountId" reference="Accounts">
                <TextField source="username"/>
            </ReferenceField>
            <DateField source="createdAt"/>
            <DateField source="updatedAt"/>
        </Datagrid>
    </List>
);

export const SystemlogEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id"/>
            <TextInput source="type"/>
            <TextInput source="method"/>
            <TextInput source="accessType"/>
            <TextInput source="process"/>
            <TextInput source="log"/>
            <TextInput source="severity"/>
            <ReferenceInput source="accountId" reference="Accounts">
                <SelectInput optionText="username"/>
            </ReferenceInput>
            <DateInput source="createdAt"/>
            <DateInput source="updatedAt"/>
        </SimpleForm>
    </Edit>
);
