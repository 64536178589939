import React from 'react';
import {
    Create,
    Datagrid,
    DateField,
    Edit,
    List,
    NumberField,
    NumberInput,
    RadioButtonGroupInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    EditButton
} from 'react-admin';

export const ProductList = (props: any) => (
    <List {...props}>
        <Datagrid >
            <TextField source="id"/>
            <TextField source="serial"/>
            <NumberField source="percentage"/>
            <TextField source="status"/>
            <DateField source="createdAt"/>
            <DateField source="updatedAt"/>
            {/*<ReferenceField source="productCategoryId" reference="productCategories">*/}
            {/*    <TextField source="id"/>*/}
            {/*</ReferenceField>*/}
            <ReferenceField source="stationId" reference="Stations">
                <TextField source="name"/>
            </ReferenceField>
            <EditButton/>
        </Datagrid>
    </List>
);

export const ProductEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id"/>
            <TextInput source="serial"/>
            <NumberInput source="percentage"/>
            <RadioButtonGroupInput source="status" choices={[
                { id: 'RENT', name: 'Rented' },
                { id: 'AVAILABLE', name: 'Available' }
            ]}/>
            {/*<ReferenceInput source="productCategoryId" reference="productCategories">*/}
            {/*    <SelectInput optionText="id"/>*/}
            {/*</ReferenceInput>*/}
            <ReferenceInput source="stationId" reference="Stations">
                <SelectInput optionText="name"/>
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export const ProductCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="serial"/>
            <NumberInput source="percentage"/>
            <RadioButtonGroupInput source="status" choices={[
                { id: 'RENT', name: 'Rented' },
                { id: 'AVAILABLE', name: 'Available' }
            ]}/>
            {/*<ReferenceInput source="productCategoryId" reference="productCategories">*/}
            {/*    <SelectInput optionText="id"/>*/}
            {/*</ReferenceInput>*/}
            <ReferenceInput source="stationId" reference="Stations">
                <SelectInput optionText="name"/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export const ProductShow = (props: any) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="serial"/>
            <NumberField source="percentage"/>
            <TextField source="status"/>
            <TextField source="id"/>
            <DateField source="createdAt"/>
            <DateField source="updatedAt"/>
            <ReferenceField source="productCategoryId" reference="productCategories"><TextField
                source="id"/></ReferenceField>
            <ReferenceField source="stationId" reference="stations"><TextField source="id"/></ReferenceField>
        </SimpleShowLayout>
    </Show>
);
