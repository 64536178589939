import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import LaunchIcon from '@material-ui/icons/Launch';


const useStyles = makeStyles({
    link: {
        textDecoration: 'none'
    },
    icon: {
        width: '0.5em',
        paddingLeft: 2
    }
});

export const LocationField = ({ record = {}, source }: any) => {
    const classes = useStyles();

    return (<a href={`http://maps.google.com?q=${record[source].lat},${record[source].lng}`} className={classes.link}>
        ({record[source].lat} {record[source].lng})
        <LaunchIcon className={classes.icon}/>
    </a>);
};
